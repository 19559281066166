import React from 'react';
import { graphql } from 'gatsby';
import { Trans } from 'gatsby-plugin-react-i18next';
import Layout from '../../layouts';
import DownBlock from '../../components/DownBlock/DownBlock';
import AppRating from '../../components/AppRating';
import SolutionLayout from '../../components/Solutions/SolutionLayout/SolutionsLayout';
import headSrc from '../../assets/images/solutions/digitalagencies/head.png';
import headSrc2x from '../../assets/images/solutions/digitalagencies/head_2x.png';
import Description1 from '../../assets/images/solutions/digitalagencies/description_1.svg';
import Description2 from '../../assets/images/solutions/digitalagencies/description_2.svg';
import Description3 from '../../assets/images/solutions/digitalagencies/description_3.svg';
import Description4 from '../../assets/images/solutions/digitalagencies/description_4.svg';
import block1Src from '../../assets/images/solutions/digitalagencies/block_1.png';
import block1Src2x from '../../assets/images/solutions/digitalagencies/block_1_2x.png';
import block2Src from '../../assets/images/solutions/digitalagencies/block_2.png';
import block2Src2x from '../../assets/images/solutions/digitalagencies/block_2_2x.png';
import block3Src from '../../assets/images/solutions/digitalagencies/block_3.png';
import block3Src2x from '../../assets/images/solutions/digitalagencies/block_3_2x.png';
import block4Src from '../../assets/images/solutions/digitalagencies/block_4.png';
import block4Src2x from '../../assets/images/solutions/digitalagencies/block_4_2x.png';
import block5Src from '../../assets/images/solutions/digitalagencies/block_5.png';
import block5Src2x from '../../assets/images/solutions/digitalagencies/block_5_2x.png';
import ImageContainer from '../../components/ImageContainer/ImageContainer';
import BlockTitle from '../../components/BlockTitle/BlockTitle';
import FaqDigitalAgencies from '../../components/FaqDigitalAgencies/FaqDigitalAgencies';

const DigitalAgencies = (): JSX.Element => {
  const head = {
    title: <Trans>Digital-агентства и медиабайеры</Trans>,
    text: (
      <Trans>
        Octo Browser обеспечивает эффективную и безопасную работу медиа-агентств с рекламными кампаниями клиентов, защищая от теневого бана и блокировок за мультиаккаунтинг.
      </Trans>
    ),
    images: [
      {
        image: (
          <ImageContainer
            src={headSrc}
            srcSet={{ _2x: headSrc2x }}
          />
        ),
      },
    ],
  };

  const descriptions = [
    {
      icon: <Description1 />,
      title: <Trans>Профессиональный мультиккаутинг</Trans>,
      text: <Trans>Масштабируйте рекламные кампании с помощью неограниченного количества аккаунтов, автоматизации рутинных процессов и удобной работы в команде.</Trans>,
    },
    {
      icon: <Description2 />,
      title: <Trans>Качественные подмены фингерпринта</Trans>,
      text: <Trans>Управляйте большими объемами рекламного трафика с минимальным риском блокировок аккаунтов и потери бюджетов.</Trans>,
    },
    {
      icon: <Description3 />,
      title: <Trans>Долгосрочное использование аккаунтов</Trans>,
      text: <Trans>Получите доступ к необходимым инструментам создания достоверных аккаунтов и их долгосрочного использования.</Trans>,
    },
    {
      icon: <Description4 />,
      title: <Trans>Командная работа</Trans>,
      text: <Trans>Организуйте эффективную работу большой команды с быстрой и безопасной передачей аккаунтов, используя гибкие настройки ролей и прав доступа.</Trans>,
    },
  ];

  const blocks = {
    items: [
      {
        images: [
          {
            image: (
              <ImageContainer
                src={block1Src}
                srcSet={{ _2x: block1Src2x }}
              />
            ),
          },
        ],
        title: <Trans>Достоверная проверка рекламных гипотез</Trans>,
        text: <Trans>Создавайте аккаунты с любыми параметрами и тестируйте работоспособность рекламных кампаний с самыми тонкими настройками.</Trans>,
      },
      {
        images: [
          {
            image: (
              <ImageContainer
                src={block2Src}
                srcSet={{ _2x: block2Src2x }}
              />
            ),
          },
        ],
        title: <Trans>Ретаргетинг по ключевым аудиториям</Trans>,
        text: <Trans>Разделяйте запуск рекламных кампаний на любое количество аккаунтов и анализируйте результативность для каждой отдельной аудитории.</Trans>,
      },
      {
        images: [
          {
            image: (
              <ImageContainer
                src={block3Src}
                srcSet={{ _2x: block3Src2x }}
              />
            ),
          },
        ],
        title: <Trans>Увеличение объема рекламных сообщений</Trans>,
        text: <Trans>Повышайте эффективность продвижения бренда, разнообразив целевые рекламные сообщения при помощи мультиаккаунтинга.</Trans>,
      },
      {
        images: [
          {
            image: (
              <ImageContainer
                src={block4Src}
                srcSet={{ _2x: block4Src2x }}
              />
            ),
          },
        ],
        title: <Trans>Исследование конкурентов</Trans>,
        text: <Trans>Отслеживайте запущенные рекламные кампании и креативы конкурентов, непосредственно глазами целевой аудитории.</Trans>,
      },
      {
        images: [
          {
            image: (
              <ImageContainer
                src={block5Src}
                srcSet={{ _2x: block5Src2x }}
              />
            ),
          },
        ],
        title: <Trans>Посев мнений и управление репутацией</Trans>,
        text: <Trans>Создавайте трастовые аккаунты для запуска рекламных кампаний с посевом мнений в соцсетях или на крупных форумах и медиа ресурсах.</Trans>,
      },
    ],
    title: (
      <BlockTitle
        highlightIsBlock
        highlightText={<Trans>для Digital-агентств</Trans>}
        title={<Trans>Возможности Octo Browser</Trans>}
      />
    ),
  };
  const faq = (
    <FaqDigitalAgencies
      title={
        <BlockTitle
          highlightPosition="start"
          highlightText={<><Trans>Как масштабировать рекламные компании</Trans>{' '}</>}
          title={<Trans>и эффективно управлять множеством аккаунтов</Trans>}
          maxWidthTitle={950}
        />
      }
    />
  );
  return (
    <Layout>
      <AppRating />
      <SolutionLayout
        head={head}
        descriptions={descriptions}
        blocks={blocks}
        faq={faq}
      />
      <DownBlock />
    </Layout>
  );
};

export default DigitalAgencies;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(
      filter: {
        ns: { in: ["digitalAgenciesPage", "translation"] }
        language: { eq: $language }
      }
    ) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
