import React from 'react';
import Faq from '../Faq/Faq';
import { Trans } from 'gatsby-plugin-react-i18next';
import { FaqType } from '../../types/faq.types';

const FAQS: Array<FaqType> = [
  {
    question: <Trans>Увеличение количества рекламных аккаунтов</Trans>,
    answer: (
      <>
        <p>
          <Trans>
            Для эффективного привлечения трафика, digital-агентствам требуется множество рекламных аккаунтов на платформах контекстной рекламы и социальных сетях. Но на большинстве платформ мультиаккаунт запрещен.
          </Trans>
        </p>
        <p>
          <Trans>
            Octo Browser позволяет обойти ограничения платформ и создать необходимое количество аккаунтов с браузерным отпечатком реальных устройств.
          </Trans>
        </p>
      </>
    ),
  },
  {
    question: <Trans>Снижение рисков блокировки аккаунтов</Trans>,
    answer: (
      <>
        <p>
          <Trans>
            Facebook, Instagram, Google и другие платформы тщательно отслеживают параметры устройств пользователей и в случае возникновения несоответствий или подозрительного поведения, запрашивают дополнительную верификацию. Но что делать, если быстрое переключение между рекламными аккаунтами необходимо для работы агентства, учитывая, что кроме неудобства, существует риск полной потери доступа к аккаунту?</Trans>
        </p>
        <p>
          <Trans>
            Octo Browser позволяет управлять множеством аккаунтов из одного браузера, не опасаясь риска блокировки. Критические данные и история хранятся отдельно для каждого профиля, в защищенном облачном хранилище, не позволяя обнаружить связь между ними.</Trans>
        </p>
      </>
    ),
  },
  {
    question: <Trans>Быстрая и безопасная передача аккаунтов</Trans>,
    answer: (
      <Trans>
        При увеличении масштабов работы агентства, неизбежно потребуется передавать отдельные задачи, аккаунты клиентов или целые направления работы. В Octo Browser реализована удобная настройка ролей и прав доступа, позволяющая организовывать работу большой команды. Передача виртуальных профилей происходит без изменений цифрового отпечатка, что делает передачу незаметной для платформ. А доступ в учётные записи осуществляется без открытия данных для авторизации, что дополнительно защищает аккаунты от кражи.
      </Trans>
    ),
  },
];

type FaqDigitalAgenciesProps = {
  title: JSX.Element;
};
const FaqDigitalAgencies = ({ title }: FaqDigitalAgenciesProps): React.ReactElement => {
  return <Faq isWhite faqs={FAQS} title={title} />;
};

export default FaqDigitalAgencies;
